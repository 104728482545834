import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { LoadingSpinner } from '../ui'
import { LinkModal } from '../ui/modals'
import { CONSENT_AGREEMENT } from '../../utils/constants/consent'
import { TERMS } from '../../utils/constants/terms'
import { PRIVACY } from '../../utils/constants/privacy'
import { isBundle } from '../../config'

const formatAmount = (amount) => {
  return (amount / 100).toFixed(2)
}

class PaymentForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cardBrand: '',
      nonce: undefined,
      agreed: false,
      isReady: false,
      errors: [],
    }
    this.requestCardNonce = this.requestCardNonce.bind(this)
  }

  requestCardNonce() {
    if (!this.state.isReady) {
      return
    }
    if (this.state.agreed) {
      this.paymentForm.requestCardNonce()
    } else {
      document.getElementById('error').innerHTML = 'Please read and agree to our policies.'
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.isLoaded || this.props.isLoaded === prevProps.isLoaded) {
      return
    }

    const config = {
      applicationId: this.props.appId,
      inputClass: 'sq-input',
      autoBuild: false,
      inputStyles: [
        {
          fontSize: '14px',
          lineHeight: '20px',
          fontFamily: 'Helvetica Neue',
          padding: '12px 8px',
          color: '#000',
          backgroundColor: '#f5f5f5',
        },
      ],
      cardNumber: {
        elementId: 'sq-card-number',
        placeholder: '• • • •  • • • •  • • • •  • • • •',
      },
      cvv: {
        elementId: 'sq-cvv',
        placeholder: 'CVV',
      },
      expirationDate: {
        elementId: 'sq-expiration-date',
        placeholder: 'MM/YY',
      },
      postalCode: {
        elementId: 'sq-postal-code',
        placeholder: 'Zip',
      },

      callbacks: {
        cardNonceResponseReceived: (errors, nonce, cardData) => {
          const errorList = document.getElementById('error')
          if (errors) {
            let error_html = ''
            for (let i = 0; i < errors.length; i++) {
              error_html += '<li> ' + errors[i].message + ' </li>'
            }
            errorList.innerHTML = error_html
            errorList.style.display = 'inline-block'
            return
          }
          errorList.style.display = 'none'
          errorList.innerHTML = ''
          this.props.onSubmit({
            nonce,
          })
        },
        unsupportedBrowserDetected: () => {},
        inputEventReceived: (inputEvent) => {
          switch (inputEvent.eventType) {
            case 'focusClassAdded':
              break
            case 'focusClassRemoved':
              break
            case 'errorClassAdded':
              document.getElementById('error').innerHTML = 'Please fix card information errors before continuing.'
              break
            case 'errorClassRemoved':
              document.getElementById('error').style.display = 'none'
              break
            case 'cardBrandChanged':
              if (inputEvent.cardBrand !== 'unknown') {
                this.setState({
                  cardBrand: inputEvent.cardBrand,
                })
              } else {
                this.setState({
                  cardBrand: '',
                })
              }
              break
            case 'postalCodeChanged':
              break
            default:
              break
          }
        },
        paymentFormLoaded: () => {
          this.setState({ isReady: true }, () => {
            document.getElementById('agree').style.display = 'inline'
          })
        },
      },
    }
    this.paymentForm = new this.props.paymentForm(config)
    this.paymentForm.build()
  }

  handleAgreed = (event) => this.setState({ agreed: event.target.checked })

  render() {
    const { isReady } = this.state
    const {
      isSubmitting,
      errors,
      isLoaded,
      isTestOrderForm,
      // isBundleOrderForm,
      message,
      amount,
      shippingPrice,
    } = this.props
    return (
      <>
        {isReady && (
          <div className="section header">
            <div className="section-title">Checkout</div>
          </div>
        )}
        {message && <div>{message}</div>}
        {(isSubmitting || !isReady || !isLoaded) && (
          <div className="PaymentLoader">
            <LoadingSpinner />
          </div>
        )}
        <div className="Form section stretch PaymentForm">
          <div id="sq-ccbox" className="form-fields">
            <div id="cc-field-wrapper">
              <div id="sq-card-number" className="Field"></div>
              <input type="hidden" id="card-nonce" name="nonce" className="Field" />
              <div id="sq-expiration-date" className="Field"></div>
              <div id="sq-cvv" className="Field"></div>
            </div>
            <div id="sq-postal-code" className="Field"></div>
            {isReady && isTestOrderForm && !isBundle && (
              <div className="PaymentLineItems">
                <div className="section header">
                  <div className="section-title">Price</div>
                </div>
                <div className="line-item">
                  <span>Test:</span>
                  <span>{`$${(amount / 100).toFixed(2)}`}</span>
                </div>
                <div className="line-item">
                  <span>Shipping:</span>
                  <span>{`$${(shippingPrice / 100).toFixed(2)}`}</span>
                </div>
                <hr />
                <div className="line-item">
                  <span>Total:</span>
                  <span>{`$${((amount + shippingPrice) / 100).toFixed(2)}`}</span>
                </div>
              </div>
            )}
            {isReady && isBundle && (
              <div className="PaymentLineItems">
                <div className="section header">
                  <div className="section-title">Price</div>
                </div>
                <div className="line-item">
                  <span>Bundle:</span>
                  <span>{`$${(amount / 100).toFixed(2)}`}</span>
                </div>
                <hr />
                <div className="line-item">
                  <span>Total:</span>
                  <span>{`$${(amount / 100).toFixed(2)}`}</span>
                </div>
              </div>
            )}

            <div className="Field inline" id="agree" style={{ display: 'none' }}>
              <div className="Choices">
                <label className="ChoiceInput">
                  <div className="label-container">I agree to StreamMD's</div>
                  <input
                    name="agree"
                    type="checkbox"
                    // value="false"
                    checked={this.state.agreed}
                    onChange={this.handleAgreed}
                  />
                  <div className="pseudo-input-container"></div>
                </label>
                <LinkModal link="consent" title={CONSENT_AGREEMENT.title} body={CONSENT_AGREEMENT.body} />,
                <LinkModal link="terms" title={TERMS.title} body={TERMS.body} />,
                <LinkModal link="privacy" title={PRIVACY.title} body={PRIVACY.body} />
                <div className="policy-text">policies.</div>
              </div>
            </div>
          </div>
          <div id="error" className="error"></div>

          {errors &&
            errors.length &&
            errors.map((error) => <div className="error">{error.message || error.detail}</div>)}
        </div>
        {isReady && (
          <div className="section fixed footer">
            <button className="button button-credit-card" disabled={isSubmitting} onClick={this.requestCardNonce}>
              Pay{' '}
              {amount &&
                `$${isTestOrderForm && !isBundle ? formatAmount(amount + shippingPrice) : formatAmount(amount)}`}
            </button>
            <div className="link" onClick={() => this.props.history.goBack()}>
              Back
            </div>
          </div>
        )}
      </>
    )
  }
}

export default withRouter(PaymentForm)
