import React from 'react'
import Icon from './Icon'

const Test = (props) => (
  <Icon viewBox="7 6 32 32" {...props} className="fill-none">
    <g clipPath="url(#clip0)" strokeWidth={2.5} strokeMiterlimit={10} strokeLinecap="square">
      <path d="M20.643 17.667l3.269 2.305" className="stroke tertiary" />
      <path d="M17.762 21.752l3.268 2.306" className="stroke secondary" />
      <path d="M14.88 25.838l3.268 2.306" className="stroke tertiary" />
      <path
        d="M25.108 6.131l-2.305 3.27 2.451 1.728L13.15 28.29a5 5 0 0 0 8.172 5.764l12.104-17.161 2.452 1.73 2.305-3.27-13.075-9.222z"
        className="stroke primary"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" transform="rotate(35.196 8.465 30.535)" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </Icon>
)

export default Test
