import React, { useEffect, useState } from 'react'
import { get, isEmpty, find, filter } from 'lodash'
import { useQuery } from 'react-query'
import moment from 'moment'
import DayPicker from 'react-day-picker'
import classNames from 'classnames'
import { protectedRequest } from '../../../utils/requests/api'
import { useQueryParam, StringParam } from 'use-query-params'
import {
  addAppointmentPatientData,
  createAppointment,
  followUpAppointment,
  removeAppointment,
  rescheduleAppointment,
} from '../../../utils/hooks/useAppointment'
import { LoadingSpinner } from '../../../components/ui'

import 'react-day-picker/lib/style.css'

import { Slots } from '../../../components/appointments'
import { STATE_OPTIONS } from '../../../utils/states/states.util'
import Select from 'react-select'
// import { reduceNumberOfSlots } from '../../../utils/slots/slots.util'
import { isBundle } from '../../../config'

const isToday = (date) => moment(date).isSame(moment(), 'day')
const isFetchedDateDiffMinDate = (date, minDate) =>
  moment(minDate).startOf('day').format() !== moment(date).startOf('day').format()
// Min date should be first date with available slots
// Some other date may not have available slots but that one isn't minDate if minDate diff from today
const shouldSetNewMinDate = (date, minDate) => isToday(minDate) && isFetchedDateDiffMinDate(date, minDate)
//
const handleStripeCancel = (
  location,
  history,
  appointmentId,
  reschedule,
  followUp,
  setIsCleaningUp,
  setReady
) => () => {
  if (appointmentId && !reschedule && !followUp) {
    setIsCleaningUp('true')
    removeAppointment(appointmentId)
      .then(() => {
        history.push('/schedule')
        setIsCleaningUp(null)
        setReady(true)
      })
      .catch(() => {
        setIsCleaningUp(null)
        setReady(true)
      })
  } else {
    setReady(true)
  }
}

//todo: allow scheduling without payment if bundle && they have a paid testorder without an ended appointment
//i'm returning their testorders on the me object when signing in but we should probably add an endpoint that checks "canScheduleBundleAppt"
const ScheduleAppointment = ({ location, history }) => {
  const [appointmentId, setAppointmentId] = useQueryParam('appointmentId', StringParam)
  const [reschedule] = useQueryParam('reschedule', StringParam)
  const [followUp] = useQueryParam('followUp', StringParam)
  const [USState, setUSState] = useQueryParam('state', StringParam)
  const [minDate, setMinDate] = useState(new Date())
  const [isSubmitting, setIsSubmitting] = useState()
  const [isCleaningUp, setIsCleaningUp] = useState()
  const [ready, setReady] = useState()
  useEffect(handleStripeCancel(location, history, appointmentId, reschedule, followUp, setIsCleaningUp, setReady), [])
  const [date, setDate] = useState(new Date())
  const [time, setTime] = useState()
  const [providers, setProviders] = useState()
  const [selectedSlot, setSelectedSlot] = useState()
  const [message, setMessage] = useState()
  const dateParam = moment(date).format('YYYY-MM-DD')
  const startDate = moment(date).startOf('day').utc().format()
  const endDate = moment(date).endOf('day').utc().format()
  //
  const { data, status, error, refetch } = useQuery(
    `slots.${dateParam}.${ready}.${USState}`,
    () => ready && USState && protectedRequest('GET', `calendar?start=${startDate}&end=${endDate}&state=${USState}`),
    {
      retry: false,
    }
  )
  let slots = get(data, 'data.slots', [])
  // const slots = reduceNumberOfSlots(get(data, 'data.slots', []), get(data, 'data.date', moment().format()))

  useEffect(() => {
    refetch()
    setSelectedSlot()
    setProviders()
    setTime()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [USState])

  if (ready && status === 'success' && data && !Object.keys(slots).length) {
    setDate(moment(date).add(1, 'd').toDate())
  } else if (ready && status === 'success' && data && Object.keys(slots).length && shouldSetNewMinDate(date, minDate)) {
    setMinDate(moment(date).toDate())
  }

  const onSubmit = () => {
    setIsSubmitting('true')
    let promise
    if (reschedule) {
      promise = rescheduleAppointment(appointmentId, providers, selectedSlot, USState).then(() =>
        history.push('/dashboard')
      )
    } else if (followUp) {
      promise = followUpAppointment(appointmentId, providers, selectedSlot).then(() => history.push('/dashboard'))
    } else {
      promise = createAppointment(providers, selectedSlot, isBundle, USState).then((response) => {
        setAppointmentId(response.id)
        // TODO: EP confirm appointment
        if (isBundle) {
          const notes = JSON.parse(localStorage.getItem('intake'))
          addAppointmentPatientData(response.id, notes).then(() => history.push('/dashboard'))
        } else {
          history.push('/appointment/checkout?appointmentId=' + response.id)
        }
      })
    }

    promise.catch((error) => {
      setIsSubmitting(null)
      console.log(error)
      setMessage(error?.response?.data?.error || 'Error Message')
    })
  }

  let submitTitle = ''
  if (reschedule) {
    submitTitle = 'Reschedule'
  } else if (followUp || isBundle) {
    submitTitle = 'Schedule'
  } else {
    submitTitle = 'Continue to Payment'
  }

  if (isSubmitting) {
    return <LoadingSpinner />
  }

  return (
    <div className="ScheduleAppointment View">
      <div className="section header">
        <div className="section-title">Where will you be during your appointment?</div>
        <Select
          classNamePrefix="select"
          className="Select"
          options={STATE_OPTIONS}
          value={find(STATE_OPTIONS, ({ value }) => value === USState)}
          onChange={(option) => {
            setUSState(option.value)
          }}
        />
      </div>

      <div className="section stretch header">
        {USState && (
          <>
            <div className="section-title">Schedule an appointment</div>
            {message && <div>{message}</div>}
            <DayPicker
              onDayClick={(date) => {
                setDate(date)
                setTime(null)
              }}
              selectedDays={date}
              disabledDays={{ before: minDate }}
              showOutsideDays={true}
            />
            {status === 'loading' || !ready ? (
              <LoadingSpinner />
            ) : // TODO: Fix this bad dumb code
            Object.keys(slots).length === 0 || error || isEmpty(USState) ? (
              <div className="no-slots-message">
                {isEmpty(USState) ? ' Please select a state' : 'No available appointment times for this state'}
              </div>
            ) : (
              <Slots
                slots={filter(slots, (slot) => {
                  const today = moment()
                  let diff = moment(slot.when).diff(today, 'hours')
                  console.log(diff, today.toDate(), moment(slot.when).toDate())
                  return diff >= 2
                })}
                onClick={({ time, when, providers }) => {
                  setProviders(providers)
                  setSelectedSlot(when)
                  setTime(time)
                }}
                times={[time]}
              />
            )}
          </>
        )}
      </div>
      <div className="section fixed footer">
        <button
          className={classNames('button primary', {
            inactive: !!isSubmitting || !!isCleaningUp || !providers || (providers && !providers.length),
          })}
          disabled={isSubmitting}
          onClick={onSubmit}
        >
          {submitTitle}
        </button>
      </div>
    </div>
  )
}

export default ScheduleAppointment
