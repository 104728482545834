import { get, map } from 'lodash'
import * as Yup from 'yup'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import moment from 'moment'
import {
  Contact,
  NoContact,
  Thermometer,
  Intestine,
  Lungs,
  Breath,
  Cough,
  BloodCough,
  Dizziness,
  SoreThroat,
  Fatigue,
  MusclePain,
  Chills,
  Congestion,
  Nausea,
  QuestionMark,
} from '../../components/icons'
import { States, TextStates } from '../../utils/states/states.util'

const RISKS = { HIGH: 'high', ER: 'ER', LOW: 'low' }

export const YES_NO_CHOICES = [
  {
    label: 'Yes',
    value: 'true',
  },
  {
    label: 'No',
    value: 'false',
  },
]

const SYMPTOM_DETAILS_FIELDS = [
  {
    name: 'symptoms_description',
    shortLabel: 'How long have you had these symptoms?',
    placeholder: 'How long have you been experiencing the symptoms above, if any',
    component: 'textarea',
  },
]

const SYMPTOM_OTHER_FIELDS = [
  {
    name: 'symptoms_other',
    shortLabel: 'Tell us more about your other symptoms?',
    placeholder: 'Tell us more about the other symptoms you are experiencing',
    component: 'textarea',
  },
]

const SYMPTOM_FIELDS = [
  {
    name: 'symptoms',
    shortLabel: 'Symptoms',
    component: 'checkbox',
    choices: [
      {
        label: 'Fever',
        value: 'fever',
        icon: Thermometer,
        risk: RISKS.HIGH,
      },
      {
        label: 'Cough',
        value: 'cough',
        icon: Cough,
        risk: RISKS.HIGH,
      },
      {
        label: 'Sore Throat',
        value: 'sore_throat',
        icon: SoreThroat,
        risk: RISKS.LOW,
      },
      {
        label: 'Nausea / Vomiting',
        value: 'nausea_vomiting',
        icon: Nausea,
        risk: RISKS.LOW,
      },
      {
        label: 'Shortness of Breath',
        value: 'difficulty_breathing',
        icon: Breath,
        risk: RISKS.HIGH,
      },
      {
        label: 'Difficulty Breathing',
        value: 'severe_difficulty_breathing',
        icon: Lungs,
        risk: RISKS.ER,
      },
      {
        label: 'Lightheaded or Dizzy',
        value: 'lightheaded_dizzy',
        icon: Dizziness,
        risk: RISKS.ER,
      },

      {
        label: 'Coughing Up Blood',
        value: 'cough_blood',
        icon: BloodCough,
        risk: RISKS.ER,
      },

      {
        label: 'Fatigue',
        value: 'fatigue',
        icon: Fatigue,
        risk: RISKS.LOW,
      },
      {
        label: 'Abdominal Pain',
        value: 'abdominal_pain',
        icon: Intestine,
        risk: RISKS.LOW,
      },
      {
        label: 'Muscle or Body Aches',
        value: 'muscle_body_aches',
        icon: MusclePain,
        risk: RISKS.LOW,
      },
      {
        label: 'Chills',
        value: 'chills',
        icon: Chills,
        risk: RISKS.LOW,
      },
      {
        label: 'Congestion',
        value: 'congestion',
        icon: Congestion,
        risk: RISKS.LOW,
      },
      {
        label: 'Other',
        value: 'other',
        icon: QuestionMark,
        risk: RISKS.LOW,
      },
    ],
  },
]

const EXPOSURE_FIELDS = [
  {
    name: 'exposure_people',
    component: 'radio',
    shortLabel: 'Exposure 14 days',
    validation: Yup.string().required('required'),
    // readOnlyCondition: value => value === 'true',
    choices: [
      {
        label: 'Yes',
        value: 'true',
        icon: Contact,
      },
      {
        label: 'No',
        value: 'false',
        icon: NoContact,
      },
    ],
  },
]

const HEALTHCARE_FIELDS = [
  {
    name: 'exposure_healthcare',
    shortLabel: 'Healthcare Worker',
    component: 'radio',
    choices: YES_NO_CHOICES,
    validation: Yup.string().required('required'),
  },
]

const FACILITY_FIELDS = [
  {
    name: 'exposure_facility',
    shortLabel: 'Long Term Care Facility',
    component: 'radio',
    choices: YES_NO_CHOICES,
    validation: Yup.string().required('required'),
  },
]

const PREGNANT_FIELDS = [
  {
    name: 'pregnant',
    component: 'radio',
    shortLabel: 'Pregnant',
    choices: YES_NO_CHOICES,
    // validation: Yup.string().required('required'),
  },
]

const REVIEW_OF_SYSTEMS_FIELDS = [
  {
    name: 'review_of_systems',
    label: 'Review of Systems',
    modal: true,
    fieldGroups: [
      {
        label: 'Constitutional',
        name: 'constitutional',
        allowCheckAll: true,
        fields: [
          {
            label: 'Fever',
            name: 'fever',
            shortLabel: 'Fever',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Chills',
            name: 'chills',
            shortLabel: 'Chills',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Fatigue',
            name: 'fatigue',
            shortLabel: 'fatigue',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Feeling Poorly',
            name: 'feeling_poorly',
            shortLabel: 'Feeling Poorly',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Sweats',
            name: 'sweats',
            shortLabel: 'Sweats',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Weight Gain',
            name: 'weight_gain',
            shortLabel: 'Weight Gain',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Weight Loss',
            name: 'weight_loss',
            shortLabel: 'Weight Loss',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Unexpected Weight Change',
            name: 'unexpected_weight_change',
            shortLabel: 'Unexpected Weight Change',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Sleep Disturbances',
            name: 'sleep_disturbances',
            shortLabel: 'Sleep Disturbances',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
        ],
      },
      {
        label: 'Head, Eyes, Ears, Nose & Throat',
        name: 'head_eyes_ears_nose_throat',
        allowCheckAll: true,
        fields: [
          {
            label: 'Vision Problem',
            name: 'vision_problem',
            shortLabel: 'Vision Problem',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Decreased Hearing',
            name: 'decreased_hearing',
            shortLabel: 'Decreased Hearing',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Double Vision',
            name: 'double_vision',
            shortLabel: 'Double Vision',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Light Sensitivity',
            name: 'light_sensitivity',
            shortLabel: 'Light Sensitivity',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Itchy Eyes',
            name: 'itchy_eyes',
            shortLabel: 'Itchy Eyes',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Red Eyes',
            name: 'red_eyes',
            shortLabel: 'Red Eyes',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Eye Pain',
            name: 'eye_pain',
            shortLabel: 'Eye Pain',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Runny Nose',
            name: 'runny_nose',
            shortLabel: 'Runny Nose',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Neck Stiffness',
            name: 'neck_stiffnes',
            shortLabel: 'Neck Stiffness',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Nosebleed',
            name: 'nosebleed',
            shortLabel: 'Nosebleed',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Congestion',
            name: 'congestion',
            shortLabel: 'Congestion',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Snoring',
            name: 'snoring',
            shortLabel: 'Snoring',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Dry Mouth',
            name: 'dry_mouth',
            shortLabel: 'Dry Mouth',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Flu-Like Symptoms',
            name: 'flu_like_symptoms',
            shortLabel: 'Flu-Like Symptoms',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Sore Throat',
            name: 'sore_throat',
            shortLabel: 'Sore Throat',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Hoarseness',
            name: 'hoarseness',
            shortLabel: 'Hoarseness',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Ringing In Ears',
            name: 'ringing_in_ears',
            shortLabel: 'Ringing In Ears',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Vertigo',
            name: 'vertigo',
            shortLabel: 'Vertigo',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Earache',
            name: 'earache',
            shortLabel: 'Earache',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
        ],
      },
      {
        label: 'Cardiovascular',
        name: 'cardiovascular',
        allowCheckAll: true,
        fields: [
          {
            label: 'Chest Pain',
            name: 'chest_pain',
            shortLabel: 'Chest Pain',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Palpitations',
            name: 'palpitations',
            shortLabel: 'Palpitations',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Leg Swelling',
            name: 'leg_swelling',
            shortLabel: 'Leg Swelling',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Cold Extremities',
            name: 'cold_extremities',
            shortLabel: 'Cold Extremities',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Cold Hands or Feet',
            name: 'cold_hands_or_feet',
            shortLabel: 'Cold Hands or Feet',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Leg Pain w/ Walking',
            name: 'leg_pain_walking',
            shortLabel: 'Leg Pain w/ Walking',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Irregular Heart Rhythm',
            name: 'irregular_heart_rhythm',
            shortLabel: 'Irregular Heart Rhythm',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
        ],
      },
      {
        label: 'Respiratory',
        name: 'respiratory',
        allowCheckAll: true,
        fields: [
          {
            label: 'Shortness of Breath',
            name: 'shortness_of_breath',
            shortLabel: 'Shortness of Breath',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Cough',
            name: 'cough',
            shortLabel: 'Cough',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Rapid Breathing',
            name: 'rapid_breathing',
            shortLabel: 'Rapid Breathing',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Wheezing',
            name: 'wheezing',
            shortLabel: 'Wheezing',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Chest Congestion',
            name: 'chest_congestion',
            shortLabel: 'Chest Congestion',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Coughing Up Blood',
            name: 'coughing_up_blood',
            shortLabel: 'Coughing Up Blood',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
            group: 'Respiratory',
          },
          {
            label: 'Coughing Up Sputum',
            name: 'coughing_up_sputum',
            shortLabel: 'Coughing Up Sputum',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
        ],
      },
      {
        label: 'Gastrointestinal',
        name: 'gastrointestinal',
        allowCheckAll: true,
        fields: [
          {
            label: 'Abdominal Pain',
            name: 'abdominal_pain',
            shortLabel: 'Abdominal Pain',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Blood in Stool',
            name: 'blood_in_stool',
            shortLabel: 'Blood in Stool',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Vomiting',
            name: 'vomiting',
            shortLabel: 'vomiting',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Nausea',
            name: 'nausea',
            shortLabel: 'Nausea',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Diarrhea',
            name: 'diarrhea',
            shortLabel: 'Diarrhea',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Black/Tarry Stools',
            name: 'black_tarry_stools',
            shortLabel: 'Black/Tarry Stools',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Decreased Appetite',
            name: 'decreased_appetite',
            shortLabel: 'Decreased Appetite',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Yellow Skin',
            name: 'yellow_skin',
            shortLabel: 'Yellow Skin',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Change in Bowels',
            name: 'change_in_bowels',
            shortLabel: 'Change in Bowels',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Vomiting Blood',
            name: 'vomiting_blood',
            shortLabel: 'Vomiting Blood',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Bowel Incontinence',
            name: 'bowel_incontinence',
            shortLabel: 'Bowel Incontinence',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Rectal Pain',
            name: 'rectal_pain',
            shortLabel: 'Rectal Pain',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
          {
            label: 'Painful Swallowing',
            name: 'painful_swallowing',
            shortLabel: 'Painful Swallowing',
            component: 'radio',
            className: 'inline tight',
            choices: YES_NO_CHOICES,
            validation: Yup.string().required('required'),
          },
        ],
      },
    ],
  },
]

const PAST_MEDICAL_FIELDS = [
  {
    name: 'medical_history',
    label: 'Medical History',
    modal: true,
    allowCheckAll: true,
    fields: [
      {
        label: 'Diabetes',
        name: 'diabetes',
        shortLabel: 'Diabetes',
        component: 'radio',
        className: 'inline tight',
        choices: YES_NO_CHOICES,
        highRisk: true,
        validation: Yup.string().required('required'),
      },
      {
        name: 'hypertension',
        label: 'Hypertension',
        shortLabel: 'Hypertension',
        component: 'radio',
        className: 'inline tight',
        choices: YES_NO_CHOICES,
        validation: Yup.string().required('required'),
      },
      {
        name: 'aids',
        label: 'AIDS/HIV',
        shortLabel: 'AIDS/HIV',
        component: 'radio',
        className: 'inline tight',
        choices: YES_NO_CHOICES,
        highRisk: true,
        validation: Yup.string().required('required'),
      },
      {
        name: 'autoimmune',
        label: 'Autoimmune Disease',
        shortLabel: 'Autoimmune Disease',
        component: 'radio',
        className: 'inline tight',
        choices: YES_NO_CHOICES,
        highRisk: true,
        validation: Yup.string().required('required'),
      },
      {
        name: 'cancer',
        label: 'Cancer',
        shortLabel: 'Cancer',
        component: 'radio',
        className: 'inline tight',
        choices: YES_NO_CHOICES,
        highRisk: true,
        validation: Yup.string().required('required'),
      },
      {
        name: 'kidney',
        label: 'Chronic Kidney Disease/Dialysis Patient',
        shortLabel: 'Kidney Disease',
        component: 'radio',
        className: 'inline tight',
        choices: YES_NO_CHOICES,
        highRisk: true,
        validation: Yup.string().required('required'),
      },
      {
        name: 'liver',
        label: 'Chronic LIver Disease/Cirrhosis',
        shortLabel: 'Liver Disease',
        component: 'radio',
        className: 'inline',
        choices: YES_NO_CHOICES,
        highRisk: true,
        validation: Yup.string().required('required'),
      },
      {
        name: 'lung',
        label: 'Chronic Lung Disease/Severe Asthma',
        shortLabel: 'Lung Disease',
        component: 'radio',
        className: 'inline',
        choices: YES_NO_CHOICES,
        highRisk: true,
        validation: Yup.string().required('required'),
      },
      {
        name: 'heart',
        label: 'Chronic Heart Disease',
        shortLabel: 'Heart Disease',
        component: 'radio',
        className: 'inline',
        choices: YES_NO_CHOICES,
        highRisk: true,
        validation: Yup.string().required('required'),
      },
      {
        name: 'organ',
        label: 'Organ Transplant',
        shortLabel: 'Organ Transplant',
        component: 'radio',
        className: 'inline',
        choices: YES_NO_CHOICES,
        highRisk: true,
        validation: Yup.string().required('required'),
      },
      {
        name: 'stroke',
        label: 'Stroke',
        shortLabel: 'Stroke',
        component: 'radio',
        className: 'inline',
        choices: YES_NO_CHOICES,
        validation: Yup.string().required('required'),
      },
      {
        name: 'heart_attack',
        label: 'Heart Attack',
        shortLabel: 'Heart Attack',
        component: 'radio',
        className: 'inline',
        choices: YES_NO_CHOICES,
        validation: Yup.string().required('required'),
      },
      {
        name: 'blood_clots',
        label: 'Blood Clots',
        shortLabel: 'Blood Clots',
        component: 'radio',
        className: 'inline',
        choices: YES_NO_CHOICES,
        validation: Yup.string().required('required'),
      },
      {
        name: 'steroids',
        label: 'History of Long Term Steroid Use',
        shortLabel: 'Steroid Use',
        component: 'radio',
        className: 'inline',
        choices: YES_NO_CHOICES,
        highRisk: true,
        validation: Yup.string().required('required'),
      },
      {
        name: 'congestive_heart_failure',
        label: 'Congestive Heart Failure',
        shortLabel: 'Heart Failure',
        component: 'radio',
        className: 'inline',
        choices: YES_NO_CHOICES,
        highRisk: true,
        validation: Yup.string().required('required'),
      },
      {
        name: 'other_conditions',
        label: 'Please enter any medical conditions (Past/Present)',
        shortLabel: 'Other',
        placeholder: 'Medical Conditions',
        component: 'textarea',
      },
    ],
  },
]

const SURGICAL_HISTORY_FIELDS = [
  {
    name: 'surgical_history',
    label: 'Please enter any surgical history',
    shortLabel: 'Surgical History',
    placeholder: 'Surgical History',
    component: 'textarea',
    // validation: Yup.string().required('required'),
  },
]

const FAMILY_HISTORY_FIELDS = [
  {
    name: 'family_history',
    label: 'Relatives Condition',
    shortLabel: 'Family History',
    placeholder: 'Family History',
    component: 'textarea',
  },
]

const SOCIAL_HISTORY_FIELDS = [
  {
    name: 'social_history',
    label: 'Social History',
    modal: true,
    fields: [
      {
        label: 'Do you smoke',
        shortLabel: 'Smoker',
        name: 'smoke',
        component: 'radio',
        className: 'inline',
        choices: YES_NO_CHOICES,
        validation: Yup.string().required('required'),
      },
      {
        label: 'If Yes, for how long?',
        shortLabel: 'Years Smoking',
        component: 'input',
        name: 'smoke_years',
        placeholder: 'Years',
        className: 'inline',
      },
      {
        label: 'If Yes, how many packs a day?',
        shortLabel: 'Packs a day',
        name: 'tobacco_packs',
        component: 'input',
        placeholder: 'Packs',
        className: 'inline',
      },
      {
        label: 'If Yes, do you use other tobacco products?',
        name: 'tobacco_other',
        shortLabel: 'Other Tobacco',
        component: 'input',
        placeholder: 'Other products',
        className: 'inline',
      },
      {
        label: 'Do you consume alcohol?',
        shortLabel: 'Drink Alcohol',
        name: 'alcohol',
        component: 'radio',
        className: 'inline',
        choices: YES_NO_CHOICES,
        validation: Yup.string().required('required'),
      },
      {
        label: 'If Yes, how many drinks a week?',
        shortLabel: 'Weekly Drinks',
        name: 'alcohol_weekly',
        component: 'input',
        placeholder: 'Drinks a week',
        className: 'inline',
      },
    ],
  },
]

const ALLERGY_FIELDS = [
  {
    name: 'allergies',
    label: 'Allergies',
    allowMultiple: true,
    fields: [
      {
        name: 'allergy',
        shortLabel: 'Allergy',
        placeholder: 'Allergic To',
        className: 'tight',
        validation: Yup.string(),
      },
      {
        name: 'reaction',
        shortLabel: 'Reaction',
        placeholder: 'Reaction',
        className: 'tight',
        validation: Yup.string(),
      },
    ],
  },
]

const MEDICATION_FIELDS = [
  {
    name: 'medication',
    label: 'Current Medication',
    placeholder: 'Medication',
    allowMultiple: true,
    fields: [
      {
        name: 'drug',
        shortLabel: 'Drug',
        placeholder: 'Drug',
        className: 'tight',
        // validation: Yup.string(),
      },
      {
        name: 'dosage',
        shortLabel: 'Dosage',
        placeholder: 'Dosage',
        className: 'tight',
        // validation: Yup.string().required('Category is required.').nullable(),
      },
      {
        name: 'frequency',
        shortLabel: 'Frequency',
        placeholder: 'Frequency',
        className: 'tight',
        // validation: Yup.string().required('Category is required.').nullable(),
      },
    ],
  },
]

const VITALS_FIELDS = [
  {
    name: 'height',
    label: 'Height',
    shortLabel: 'Height',
    placeholder: `5' 10"`,
    mask: [/[0-9]/, '\u2032', /^\d/, /^\d/, '\u2033'],
    validation: Yup.string().required('required'),
  },
  {
    name: 'weight',
    label: 'Weight',
    shortLabel: 'Weight',
    placeholder: 'Lbs',
    mask: createNumberMask({
      prefix: '',
      suffix: ' Lbs',
    }),
    validation: Yup.string().required('required'),
  },
  {
    name: 'temperature',
    label: 'Temperature (Thermometer needed)',
    shortLabel: 'Temperature',
    placeholder: 'ºf',
    mask: createNumberMask({
      prefix: '',
      suffix: ' ºf',
      allowDecimal: true,
    }),
  },
  {
    name: 'blood_pressure',
    label: 'blood pressure (home blood pressure cuff needed)',
    fields: [
      {
        name: 'systolic',
        shortLabel: 'Systolic',
        placeholder: 'Systolic (Upper number)',
        className: 'tight',
        type: 'Number',
      },
      {
        name: 'diastolic',
        shortLabel: 'Diastolic',
        placeholder: 'Diastolic (Lower number)',
        className: 'tight',
        type: 'Number',
      },
    ],
  },
  {
    name: 'heart_rate',
    label: 'Heart Rate',
    shortLabel: 'Heart Rate',
    placeholder: 'Beats per minute',
    type: 'Number',
  },
  {
    name: 'bmi',
    label: 'BMI',
    shortLabel: 'BMI',
    placeholder: 'Body Mass Index',
    filter: ({ isDoctor }) => isDoctor,
  },
  {
    name: 'note',
    label: 'Add a note for your doctor',
    shortLabel: 'Note to Doctor',
    placeholder: 'Add note...',
    component: 'textarea',
  },
]

const ADDRESS_FIELDS = [
  {
    name: 'street',
    placeholder: 'Street Address',
    validation: Yup.string().required('required'),
  },
  {
    name: 'street2',
    placeholder: 'Street Address 2',
  },
  {
    name: 'city',
    placeholder: 'City',
  },
  {
    name: 'state',
    placeholder: 'State',
    component: 'select',
    options: map(States, (s) => ({
      value: s,
      label: TextStates[s],
    })),
    validation: Yup.string().required('required'),
  },
  {
    name: 'zipcode',
    placeholder: 'Zip Code',
    validation: Yup.string()
      .matches(/^\d{5}$|^\d{5}-\d{4}$/, 'Zipcode invalid')
      .required('required'),
  },
]

export const CHIEF_COMPLAINT = [
  {
    title: 'Chief Complaint',
    answers: [...SYMPTOM_FIELDS, ...SYMPTOM_DETAILS_FIELDS, ...SYMPTOM_OTHER_FIELDS],
  },
]

export const PRESENT_ILLNESS = [
  {
    title: 'History of Present Illness',
    canEdit: false,
    answers: [...EXPOSURE_FIELDS, ...HEALTHCARE_FIELDS, ...FACILITY_FIELDS, ...PREGNANT_FIELDS],
  },
]

export const PAST_MEDICAL_HISTORY = [
  {
    title: 'Past Medical History',
    answers: PAST_MEDICAL_FIELDS,
  },
]

export const PAST_SURGICAL_HISTORY = [
  {
    title: 'Past Surgical History',
    answers: SURGICAL_HISTORY_FIELDS,
  },
]

export const FAMILY_HISTORY = [
  {
    title: 'Family History',
    answers: FAMILY_HISTORY_FIELDS,
  },
]

export const SOCIAL_HISTORY = [
  {
    title: 'Social History',
    answers: SOCIAL_HISTORY_FIELDS,
  },
]

export const REVIEW_OF_SYSTEMS = [
  {
    title: 'Review of Systems',
    answers: REVIEW_OF_SYSTEMS_FIELDS,
  },
]

export const ALLERGIES = [
  {
    title: 'Allergies',
    answers: ALLERGY_FIELDS,
  },
]

export const MEDICATION = [
  {
    title: 'Medication',
    answers: MEDICATION_FIELDS,
  },
]

export const VITALS = [
  {
    title: 'Vitals',
    answers: VITALS_FIELDS,
  },
]

export const SYMPTOM_QUESTIONS = [
  {
    question: 'Tell us about your symptoms',
    answers: [...SYMPTOM_FIELDS],
  },
  {
    question: 'Tell us about your other symptoms',
    answers: [...SYMPTOM_OTHER_FIELDS],
    filter: ({ ...values }) => !values.symptoms.includes('other'),
  },
  {
    question: 'Some more details',
    answers: [...SYMPTOM_DETAILS_FIELDS],
  },
]

export const EXPOSURE_QUESTIONS = [
  {
    question: 'Have you been exposed to someone who has tested positive for Covid-19 in the last 14 days?',
    answers: EXPOSURE_FIELDS,
  },
  {
    question: 'Are you a healthcare worker?',
    answers: HEALTHCARE_FIELDS,
  },
  {
    question: 'Are you living in a long term care facility?',
    answers: FACILITY_FIELDS,
  },
  {
    question: 'Are you pregnant?',
    answers: PREGNANT_FIELDS,
    filter: ({ gender }) => gender === 'Male',
  },
]

export const MEDICAL_HISTORY_QUESTIONS = [
  {
    label: 'Medical History',
    question: 'Lets have a look at your medical history.',
    answers: [
      ...MEDICATION_FIELDS,
      ...ALLERGY_FIELDS,
      ...PAST_MEDICAL_FIELDS,
      ...REVIEW_OF_SYSTEMS_FIELDS,
      ...SOCIAL_HISTORY_FIELDS,
      ...SURGICAL_HISTORY_FIELDS,
      ...FAMILY_HISTORY_FIELDS,
    ],
  },
]

export const SYSTEM_QUESTIONS = [
  {
    label: 'Vitals',
    question: 'Lets have a look at your vitals.',
    answers: [...VITALS_FIELDS],
  },
]

export const ADDRESS_QUESTIONS = [
  {
    label: 'Address',
    question: 'Lets get an address to find a local licensed physician..',
    answers: [...ADDRESS_FIELDS],
  },
]

//DO NOT CHANGE THE 'assessment' properties below as backend depends on them
export const ALGO_OPTIONS = {
  LOW: {
    label: 'Low Risk',
    value: 'LOW',
    assessment: 'Low Risk for COVID-19',
    plan: 'Link to CDC Guidelines for Staying Home',
    order_test: false,
    level: 0,
  },
  INTERMEDIATE: {
    label: 'Medium Risk',
    value: 'INTERMEDIATE',
    assessment: 'Intermediate Risk for COVID19',
    plan: 'Link to CDC Guidelines for Staying Home',
    order_test: true,
    level: 1,
  },
  HIGH: {
    label: 'High Risk',
    value: 'HIGH',
    assessment: 'High Risk for COVID-19',
    plan: 'Quarantine with link',
    order_test: true,
    level: 2,
  },
  ER: {
    label: 'Emergency',
    value: 'ER',
    assessment: 'Proceed to ER',
    plan: 'Call Emergency Room',
    order_test: false,
    level: 3,
  },
}

/*
ER
  - any er risk symptom
INTERMEDIATE
  - no high risk factor + any high risk symptom
  - any risk factor + any high risk symptom
LOW
  - no high risk factor + no symptoms
  - no high risk factor + low risk symptom
  - any high risk factor + no symptoms
HIGH
  - any risk factor + any high risk symptom

Need to determine
  symptom risk level
    0: none
    1: low
    2: high
    3: er
  high risk factors
    0: none
    1: any

*/
const SYMPTOMS = get(SYMPTOM_FIELDS, '[0].choices')
const ER_SYMPTOMS = SYMPTOMS.filter(({ risk }) => risk === RISKS.ER).map(({ value }) => value)
const HIGH_SYMPTOMS = SYMPTOMS.filter(({ risk }) => risk === RISKS.HIGH).map(({ value }) => value)
const LOW_SYMPTOMS = SYMPTOMS.filter(({ risk }) => risk === RISKS.LOW).map(({ value }) => value)
const HIGH_RISK_HISTORY = PAST_MEDICAL_FIELDS[0].fields.filter((field) => field.highRisk).map(({ name }) => name)
const HIGH_RISK_QUESTIONS = ['exposure_people', 'exposure_healthcare', 'exposure_facility', 'pregnant'] //todo: do this programatically

const getSymptomRiskLevel = ({ symptoms = [] }) => {
  if (symptoms.length === 0) {
    return 0
  } else if (symptoms.some((symptom) => ER_SYMPTOMS.includes(symptom))) {
    return 3
  } else if (symptoms.some((symptom) => HIGH_SYMPTOMS.includes(symptom))) {
    return 2
  } else if (symptoms.some((symptom) => LOW_SYMPTOMS.includes(symptom))) {
    return 1
  } else {
    return 0
  }
}

export const getBMI = ({ weight, height }) => {
  weight = weight.replace(/\D/g, '') / 1
  const heightInInches = height
    .substring(0, height.length - 1)
    .split('′')
    .map((m) => m / 1)
    .reduce((total, curr, index) => (index === 0 ? curr * 12 : total + curr), 0)
  return Number(((weight / heightInInches ** 2) * 703).toFixed(1))
}

const getRiskFactorLevel = (data) => {
  let { medical_history = {}, height, weight, dob } = data
  const medicalHistory = Object.keys(medical_history).filter((key) => medical_history[key] === 'true')

  const bmi = getBMI({ height, weight })
  if (bmi > 40) {
    return 1
  }
  if (dob && moment(dob).diff(moment(), 'years') >= 65) {
    return 1
  } else if (medicalHistory.some((history) => HIGH_RISK_HISTORY.includes(history))) {
    return 1
  }

  for (const question of HIGH_RISK_QUESTIONS) {
    if (data[question] === 'true') {
      return 1
    }
  }

  return 0
}
const HIGH_RISK_FACTOR = 1
const ER_RISK_SYMPTOM = 3
const HIGH_RISK_SYMPTOM = 2
const LOW_RISK_SYMPTOM = 1
const NO_SYMPTOMS = 0

export const processIntakeData = (data) => {
  const symptomRiskLevel = getSymptomRiskLevel(data)
  if (symptomRiskLevel === ER_RISK_SYMPTOM) {
    return 'ER'
  }
  const riskFactorLevel = getRiskFactorLevel(data)
  if (riskFactorLevel >= HIGH_RISK_FACTOR) {
    if (symptomRiskLevel >= HIGH_RISK_SYMPTOM) {
      return 'HIGH'
    } else if (symptomRiskLevel >= LOW_RISK_SYMPTOM) {
      return 'INTERMEDIATE'
    } else if (symptomRiskLevel === NO_SYMPTOMS) {
      return 'LOW'
    }
  } else {
    if (symptomRiskLevel >= HIGH_RISK_SYMPTOM) {
      return 'INTERMEDIATE'
    } else {
      return 'LOW'
    }
  }
}
