import React from 'react'
import { OrderTestForm } from '../../../components/forms'
import { isBundle } from '../../../config'

const OrderTest = () => {
  const title = isBundle ? 'Where should we ship your tests?' : 'Order Test'
  return (
    <div className="OrderTest View">
      <div className="section header tight">
        {isBundle && <h1 className="primary">Order Bundle</h1>}
        <div className="section-title">{title}</div>
      </div>
      <OrderTestForm />
    </div>
  )
}

export default OrderTest
