import React, { useState } from 'react'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'
import { ConfirmModal } from './modals'

const FallbackCard = ({
  message,
  to,
  toText,
  icon: Icon,
  className,
  confirmTitle,
  confirmMessage,
  confirmButtonText,
  externalLink,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const history = useHistory()

  const go = () => {
    if (confirmMessage) {
      setIsModalOpen(true)
    } else {
      externalLink ? (window.location.href = to) : history.push(to)
    }
  }

  return (
    <>
      <div className={classNames('FallbackCard', className)} onClick={go}>
        {Icon && <Icon />}
        <div className="message">{message}</div>
        <div className="link">{toText}</div>
      </div>
      {confirmMessage && (
        <ConfirmModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          title={confirmTitle}
          message={confirmMessage}
          buttonText={confirmButtonText || 'Confirm'}
          buttonAction={() => history.push(to)}
        />
      )}
    </>
  )
}

export default FallbackCard
