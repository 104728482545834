import React from 'react'
import { Clock, Test } from '../icons'
import { FallbackCard } from '../ui'
import { isBundle, PHILIPS_APP_URL } from '../../config'

const BuyBundlePrompt = ({ hasBundleResultsPending }) => {
  const to = !isBundle ? `${PHILIPS_APP_URL}/bundle` : `/bundle`
  const externalLink = !isBundle

  const resultsPending = hasBundleResultsPending

  const icon = resultsPending ? Clock : Test
  const message = resultsPending
    ? 'Your results are on the way. Prepare for your next test in the meantime.'
    : 'You do not have a bundle currently. Purchase a bundle to get tested and schedule an appointment.'
  const toText = resultsPending ? 'Purchase another bundle' : 'Purchase a bundle'

  return <FallbackCard icon={icon} message={message} to={to} toText={toText} externalLink={externalLink} />
}

export default BuyBundlePrompt
