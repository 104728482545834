import { protectedRequest } from '../requests/api'
import { isBundle } from '../../config'

export const getSquareConnectionParams = () => protectedRequest('GET', `square/connect`).then((res) => res.data)

export const getSquarePaymentsParams = () => protectedRequest('GET', `square/payment/params`).then((res) => res.data)

export const createSquarePayment = (nonce, appointmentId) =>
  protectedRequest('POST', `square/process/appointment/payment`, { nonce, appointmentId }).then((res) => res.data)

export const createTestOrderSquarePayment = (nonce, orderId) =>
  protectedRequest('POST', `square/process/${isBundle ? 'bundle' : 'test'}/order/payment`, { nonce, orderId }).then(
    (res) => res.data
  )
