import { useQuery } from 'react-query'
import { protectedRequest } from '../requests/api'

const getBundles = async (_) => protectedRequest('GET', 'testOrder/bundles').then((res) => res.data)

export const useBundle = () => {
  //Seems like a weird way to do it to decide what call to use?
  const callToUse = getBundles
  return useQuery(['bundle'], callToUse)
}
