import React from 'react'
import moment from 'moment'
import { withRouter, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { get, set } from 'lodash'
import { Formik } from 'formik'
import * as Yup from 'yup'
import classNames from 'classnames'
import { Field } from '../fields'
import { signUpAction } from '../../resources'
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'
import { isBundle } from '../../config'

import { ToolTip } from '../ui'
// import { TZ_SUBSET } from '../../config'

const FIELDS = [
  {
    name: 'firstName',
    placeholder: 'First Name',
  },
  {
    name: 'lastName',
    placeholder: 'Last Name',
  },
  {
    name: 'email',
    placeholder: 'Email',
  },
  {
    name: 'phone',
    placeholder: 'Phone',
    mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  },
  {
    name: 'gender',
    placeholder: 'Gender',
    component: 'select',
    options: [
      { value: 'Male', label: 'Male' },
      { value: 'Female', label: 'Female' },
    ],
  },
  {
    name: 'dob',
    placeholder: 'Birthdate (mm/dd/yyyy)',
    mask: [/[0-2]/, /\d/, '/', /[0-3]/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
    pipe: createAutoCorrectedDatePipe('mm/dd/yyyy'),
    autoComplete: 'off',
  },
  {
    name: 'password',
    placeholder: 'Password',
    type: 'password',
  },
]
//
const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
const passwordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})')

// TODO: Add validation to fields above and generate from that, same with initial val
const SignupSchema = Yup.object().shape({
  firstName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('required'),
  lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('required'),
  email: Yup.string().email('Invalid email').required('required'),
  phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('required'),
  dob: Yup.string()
    .test('age', 'Must be 18 years old', (value) => moment().diff(moment(value), 'years') >= 18)
    .required('required'),
  gender: Yup.string().required('required'),
  password: Yup.string()
    .matches(passwordRegex, {
      message: (
        <ToolTip
          text="invalid password"
          tipText="Passwords must be 8+ characters, 1+ uppercase, 1+ lowercase, 1+ numeric."
        />
      ),
      excludeEmptyString: true,
    })
    .required('required'),
})

const SignUpForm = ({ history, isPhilips, philipsAppUrl }) => {
  const dispatch = useDispatch()
  const signUp = (values) => dispatch(signUpAction(values))

  const initialValues = [
    ...FIELDS,
    {
      name: 'tz',
    },
  ].reduce((initialValues, field) => set(initialValues, field.name, field.name === 'tz' ? moment.tz.guess() : ''), {})

  const onSubmit = (values, { setSubmitting, setErrors, setStatus }) => {
    console.log('submit')
    signUp(values)
      .then((response) => {
        setSubmitting(false)
        isBundle ? history.push('/bundle') : history.push('/intake')
      })
      .catch((response) => {
        setSubmitting(false)
        const errors = get(response, 'response.data.error', 'Error')
        setErrors(errors)
      })
  }

  return (
    <Formik initialValues={initialValues} validationSchema={SignupSchema} onSubmit={onSubmit}>
      {(props) => (
        <form className="Form section tight columns" onSubmit={props.handleSubmit}>
          <div className="section-title">Create an account</div>
          <div className="inner-section form-fields">
            {FIELDS.map((field) => (
              <Field key={field.name} error={props.errors.name} {...field} />
            ))}
            <button
              className={classNames('button primary', { inactive: !(props.isValid && props.dirty) })}
              type="submit"
              disabled={props.isSubmitting}
            >
              {props.isSubmitting ? 'Submitting...' : 'Sign Up'}
            </button>
            <div className="switch-form">
              Already have an account?
              <Link className="link medium" to="/login">
                Sign In
              </Link>
            </div>
            {!isPhilips && (
              <div className="switch-form">
                Philips Partner?
                <a href={`${philipsAppUrl}/signup`} className="link medium">
                  Please Click Here
                </a>
              </div>
            )}
          </div>
        </form>
      )}
    </Formik>
  )
}

export default withRouter(SignUpForm)
