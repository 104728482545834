import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import moment from 'moment-timezone'
import classNames from 'classnames'
import { withAppointment } from './AppointmentProvider'
import { AppointmentHeader, CancelAppointment, DoctorNotes } from '../../../../components/appointments'
import { VisibilityIcon } from '../../../../components/icons'
import { isBundle, CANCEL_HOURS } from '../../../../config'

const PreAppointment = ({ appointmentId, when, patient, patientData }) => {
  let history = useHistory()
  const { role } = useSelector((state) => state.auth.me)
  // const isAppointmentSoon = moment().diff(moment(when), 'minutes') >= -15 //TODO: Handle timezone
  const isAppointmentSoon = true

  const hours = moment(when).diff(moment(), 'hours')
  const canReschedule = hours >= CANCEL_HOURS

  const reschedule = () => {
    history.push(`/reschedule?appointmentId=${appointmentId}&reschedule=true`)
  }

  return (
    <div className="PreAppointment View">
      {/* TODO: Show opposite persons name */}
      <AppointmentHeader patient={patient} />
      {/* TODO: Show date */}
      {role === 'PROVIDER' ? (
        <>
          <DoctorNotes patientData={patientData} canEdit={false} />
          <div className="section fixed bottom footer">
            {/* Check if refunded or cancelled to show disabled or not */}
            <div className="button-explination top">
              *You can start your appointment within 15 minutes of the scheduled time.
            </div>
            <Link
              className={classNames('button tall call-button', { disabled: !isAppointmentSoon })}
              to={`/appointment/${appointmentId}`}
            >
              <div>Start Appointment</div>
              <div className="icon-container">
                <VisibilityIcon />
              </div>
            </Link>
            <CancelAppointment isDoctor={true} when={when} appointmentId={appointmentId} />
          </div>
        </>
      ) : (
        <>
          <div className="section">
            <div className="section-title">Your Appointment</div>
            <Link
              className={classNames('button tall call-button', { disabled: !isAppointmentSoon })}
              to={`/appointment/${appointmentId}`}
            >
              <div>Join Appointment</div>
              <div className="icon-container">
                <VisibilityIcon />
              </div>
            </Link>
            <div className="button-explination">
              *You can join your appointment within 15 minutes the scheduled time.
            </div>
          </div>
          {/* TODO Information about when to join */}
          {/* TODO Tips for call */}
          {isBundle ? (
            <>
              <div className="section bottom stretch cancel-reschedule">
                <div className="cancel-text">
                  *You may reschedule your appointment up to {CANCEL_HOURS} hours before.
                </div>
                <button className="button danger inverted" disabled={!canReschedule} onClick={() => reschedule()}>
                  Reschedule Appointment
                </button>
                <div className="message text-center">
                  Still need to cancel? {''}
                  <a target="_blank" rel="noopener noreferrer" href="mailto:team@streammd.com" className="link medium">
                    Contact Us
                  </a>
                </div>
              </div>
            </>
          ) : (
            <div className="section bottom stretch">
              <CancelAppointment isDoctor={false} when={when} appointmentId={appointmentId} />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default withAppointment(PreAppointment)
