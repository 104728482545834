import React from 'react'
import { NavBar, Card } from '../../../components/ui'
import { UpcomingAppointment } from '../../../components/dashboard'
import { Appointments, Results } from '../../../components/icons'

const PatientDashboard = () => {
  return (
    <div className="PatientDashboard View">
      <NavBar />
      <div className="section">
        <UpcomingAppointment />
      </div>
      <div className="section stretch ">
        <div className="section-title">More</div>
        <Card to="/appointments">
          <Appointments />
          <div>My Appointments</div>
        </Card>
        <Card to="/results">
          <Results />
          <div>My Results</div>
        </Card>
      </div>
      <div className="section">
        <div className="message text-center">
          Need Help? {''}
          <a target="_blank" rel="noopener noreferrer" href="mailto:team@streammd.com" className="link medium">
            Contact Us
          </a>
        </div>
      </div>
    </div>
  )
}

export default PatientDashboard
