import React from 'react'
import { Field as FormikField, connect as formikConnect, getIn } from 'formik'
import classNames from 'classnames'
import MaskedInput from 'react-text-mask'
import { Choices, SelectList, RecorderInput } from '.'
import { useField } from '../../utils/hooks/useField'

const Input = (props) => <input type="text" {...props} />

const Textarea = (props) => <textarea type="text" rows="4" {...props} />

const Select = (props) => <SelectList {...props} />

const fieldComponents = {
  input: Input,
  textarea: Textarea,
  select: Select,
}

const StandardField = ({ component, name, placeholder, mask, pipe, readOnly, type, autoComplete, options }) => {
  let AsComponent = mask ? MaskedInput : fieldComponents[component]

  return (
    <>
      <FormikField
        className="text-input"
        as={AsComponent}
        key={name}
        name={name}
        placeholder={!readOnly ? placeholder : ''}
        mask={mask}
        pipe={pipe}
        guide={mask ? false : ''}
        readOnly={readOnly}
        type={type}
        autoComplete={autoComplete}
        options={options}
      />
    </>
  )
}

const TranscribeField = ({ name }) => {
  return <FormikField component={RecorderInput} name={name} key={name} />
}

const Field = ({
  name,
  label,
  validation,
  filter,
  isDoctor,
  shortLabel,
  component = 'input',
  choices,
  className,
  readOnly,
  link,
  withRecorder,
  formik: { errors, touched, values, submitCount },
  ...rest
}) => {
  const { isRequired } = useField(validation)
  const error = getIn(errors, name)
  const touch = getIn(touched, name)
  const columnComponents = ['textarea', 'checkbox']
  const isColumn = columnComponents.includes(component)
  const shouldShow = filter ? filter({ values, name, isDoctor }) : true

  return (
    shouldShow && (
      <div className={classNames('Field', className)}>
        <div className={classNames('label-and-field', { column: isColumn })}>
          {label && (
            <div className="field-label">
              {label} {isRequired && <span className="requried">*</span>}
              {link && (
                <a className="link small" href={link} target="_blank" rel="noopener noreferrer">
                  view terms
                </a>
              )}
            </div>
          )}
          {shortLabel && <div className="read-only-label">{shortLabel}</div>}
          <div className="input-wrapper">
            <div className="error">{touch && error}</div>
            {choices ? (
              <>
                <Choices
                  className={className}
                  name={name}
                  component={component}
                  choices={choices}
                  readOnly={readOnly}
                  isRequired={isRequired}
                  error={error}
                  {...rest}
                />
              </>
            ) : (
              <StandardField name={name} component={component} readOnly={readOnly} {...rest} />
            )}
            {withRecorder && <TranscribeField name={name} />}
          </div>
        </div>
      </div>
    )
  )
}

export default formikConnect(Field)
