import React from 'react'
import { isEmpty, get } from 'lodash'
import { BuyBundlePrompt, ScheduleAppointmentPrompt } from '.'
import { useAppointment } from '../../utils/hooks/useAppointment'
import { LoadingSpinner } from '../ui'
import { AppointmentCard } from '../appointments'
import { isBundle } from '../../config'
import { useBundle } from '../../utils/hooks/useBundle'

const UpcomingAppointment = () => {
  const { status, data, error } = useAppointment()
  const { status: bundleStatus, data: bundleData, error: bundleError } = useBundle()
  const appointment = get(data, 'data', null)

  if (status === 'loading' || bundleStatus === 'loading') {
    return <LoadingSpinner />
  }

  if (status === 'error' || bundleError === 'error') {
    return <div>Error: {error.message}</div>
  }

  const hasUpcomingAppointment = !isEmpty(appointment)

  const shouldSchedule = bundleData?.bundlesToSchedule.length || !isBundle
  const hasBundleResultsPending = bundleData?.completedBundlesNoResults.length

  return (
    <>
      <div className="section-title">{shouldSchedule ? 'Get Tested' : 'Upcoming Appointment'}</div>
      {hasUpcomingAppointment ? (
        <AppointmentCard appointment={appointment} />
      ) : shouldSchedule ? (
        <ScheduleAppointmentPrompt />
      ) : (
        <BuyBundlePrompt hasBundleResultsPending={hasBundleResultsPending} />
      )}
    </>
  )
}

export default UpcomingAppointment
