import React, { useEffect } from 'react'
import { withRouter, Route, Switch, Redirect } from 'react-router-dom'
import { Appointments, PatientAppointments, Patients, StripeConnect, SquareConnect, StripeConnectOath } from './doctor'
import {
  PatientIntake,
  ScheduleAppointment,
  AppointmentCheckout,
  TestOrderCheckout,
  StripeCheckoutSuccess,
  OrderTest,
  Results,
  Result,
  AddressIntake,
  Bundle,
} from './patient'
import { ReviewResults, TestOrderShipments } from './admin'
import { Dashboard, PreAppointment, Appointment, PostAppointment, Settings, Emergency } from './'
import { Reset, Forgot, Landing, Unsupported } from '../unprotected'
import { useQueryParam, StringParam } from 'use-query-params'

const ProtectedApp = ({ history }) => {
  const [next] = useQueryParam('next', StringParam)

  useEffect(() => {
    if (next) {
      history.replace(decodeURIComponent(next))
    }
  })

  return (
    <Switch>
      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/appointments" component={Appointments} />

      {/* <Route exact path="/availability" component={ScheduleAppointment} /> */}
      <Route exact path="/appointments/user/:id" component={PatientAppointments} />
      <Route exact path="/patients" component={Patients} />
      <Route exact path="/intake" component={PatientIntake} />
      <Route exact path="/bundle" component={Bundle} />
      <Route exact path="/emergency" component={Emergency} />
      <Route exact path="/unsupported" component={Unsupported} />

      <Route exact path="/schedule" component={ScheduleAppointment} />
      <Route exact path="/reschedule" component={ScheduleAppointment} />

      <Route exact path="/appointment/checkout" component={AppointmentCheckout} />

      <Route exact path="/appointment/:id/pre" component={PreAppointment} />
      <Route exact path="/appointment/:id/" component={Appointment} />
      <Route exact path="/appointment/:id/post" component={PostAppointment} />

      <Route exact path="/address" component={AddressIntake} />

      <Route exact path="/order/test" component={OrderTest} />
      <Route exact path="/order/bundle" component={OrderTest} />
      <Route exact path="/appointment/:id/order/test/:testId" component={OrderTest} />
      <Route exact path="/order/test/checkout" component={TestOrderCheckout} />

      <Route exact path="/results" component={Results} />
      <Route exact path="/results/:id" component={Result} />

      <Route exact path="/admin/results/review" component={ReviewResults} />
      <Route exact path="/admin/order/shipments" component={TestOrderShipments} />

      <Route exact path="/square/connect" component={SquareConnect} />

      <Route exact path="/stripe/connect" component={StripeConnect} />
      <Route exact path="/stripe/connect/oauth" component={StripeConnectOath} />
      <Route exact path="/stripe/checkout/success" component={StripeCheckoutSuccess} />

      <Route exact path="/settings" component={Settings} />
      <Route exact path="/landing" component={Landing} />
      <Route exact path="/reset-password" component={Reset} />
      <Route exact path="/forgot" component={Forgot} />
      <Redirect from="/" to="/dashboard" />
    </Switch>
  )
}

export default withRouter(ProtectedApp)
