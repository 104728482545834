import React from 'react'
import Icon from './Icon'

const Video = (props) => (
  <Icon viewBox="0 0 64 64" {...props}>
    <g strokeWidth={4} fill="none" strokeMiterlimit={10}>
      <path className="stroke tertiary" data-cap="butt" d="M44 27l18-10v30L44 37" />
      <path
        d="M39 52H7a5 5 0 0 1-5-5V17a5 5 0 0 1 5-5h32a5 5 0 0 1 5 5v30a5 5 0 0 1-5 5z"
        strokeLinecap="square"
        className="stroke primary"
      />
      <circle cx={12} cy={22} r={3} strokeLinecap="square" className="stroke secondary" />
    </g>
  </Icon>
)

export default Video
