import React from 'react'
import Icon from './Icon'

const Send = (props) => (
  <Icon viewBox="0 0 16 16" {...props}>
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10}>
      <path
        d="M14.5 13.5h-13a1 1 0 0 1-1-1v-10a1 1 0 0 1 1-1h13a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1z"
        className="stroke primary"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 4.5L8 9l5.5-4.5"
        className="stroke tertiary"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 10.5L4 9M13.5 10.5L12 9"
        className="stroke secondary"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </Icon>
)

export default Send
