import React from 'react'
import Icon from './Icon'

const Clock = (props) => (
  <Icon viewBox="0 0 48 48" {...props}>
    <g strokeWidth={3} fill="none" strokeMiterlimit={10}>
      <path data-cap="butt" className="stroke secondary" d="M24 2v8M46 24h-8M24 46v-8M2 24h8" />
      <circle className="stroke primary" strokeLinecap="square" cx={24} cy={24} r={22} />
      <path className="stroke tertiary" strokeLinecap="square" d="M16 12l8 12h8" />
    </g>
  </Icon>
)

export default Clock
