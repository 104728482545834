import React from 'react'
import { useHistory } from 'react-router-dom'
import { Logo } from '../../../components/general'
import { Appointments, Clock, Results, Test, Video, Send } from '../../../components/icons'

const BUNDLE_STEPS = [
  {
    icon: Test,
    title: 'Order Bundle',
    description:
      'Order bundle, which includes 2 tests, Rapid COVID-19 Test, PCR COVID-19 Test and a video appointment with one of our StreamMD physicians.',
  },
  {
    icon: Clock,
    title: 'Wait for Tests to Arrive',
    description: 'Sit tight and wait for your tests to arrive, this can take between 1-2 days.',
  },
  {
    icon: Appointments,
    title: 'Schedule Appointment',
    description:
      'Once your tests arrive, login to this website and you will be prompted to schedule an appointment with one of our licensed StreamMD physicians. It is important that you have your test in hand during your video appointment.',
  },
  {
    icon: Video,
    title: 'Video Appointment',
    description:
      'Complete your video appointment with one of our licensed StreamMD physicians, where you will be guided on how to administer your PCR COVID test.',
  },
  {
    icon: Send,
    title: 'Send Off PCR Test',
    description:
      'Once you have completed your video appointment and have been guided on how to administer the PCR test, pack your test in the provided packaging and ship it.',
  },
  {
    icon: Results,
    title: 'Get Your Results',
    description: 'Once your results are in, we will email you to access them using this website.',
  },
]

const Bundle = () => {
  const history = useHistory()
  return (
    <div className="Bundle View">
      <div className="section header">
        <Logo />
        <div className="section-title">How It Works:</div>
      </div>
      <div className="section stretch">
        <div className="bundle-steps">
          {BUNDLE_STEPS.map(({ icon: Icon, title, description }) => (
            <div className="bundle-step">
              <div className="icon-container">{Icon && <Icon />}</div>
              <div className="title primary extra-bold">{title}</div>
              <p className="description message">{description}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="section fixed footer">
        <button className="button primary" onClick={() => history.push('/order/bundle')}>
          Order Bundle
        </button>
      </div>
    </div>
  )
}

export default Bundle
