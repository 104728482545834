import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { isNull, map, set } from 'lodash'
import { Formik } from 'formik'
import { Field } from '../fields'
import classNames from 'classnames'
import { saveAppointmentTestOrderShippingAddress } from '../../utils/hooks/useAppointment'
import { useDispatch, useSelector } from 'react-redux'
import { validateTokenAction } from '../../resources'
import { States, TextStates } from '../../utils/states/states.util'
import { createTestOrder } from '../../utils/hooks/useTestOrders'

const ORDER_TEST_FIELDS = [
  {
    name: 'street',
    placeholder: 'Street Address',
  },
  {
    name: 'street2',
    placeholder: 'Street Address 2',
  },
  {
    name: 'city',
    placeholder: 'City',
  },
  {
    name: 'state',
    placeholder: 'State',
    component: 'select',
    options: map(States, (s) => ({
      value: s,
      label: TextStates[s],
    })),
  },
  {
    name: 'zipcode',
    placeholder: 'Zip Code',
  },
]

const ORDER_TEST_SCHEMA = Yup.object().shape({
  state: Yup.string().required('required'),
  street: Yup.string().required('required'),
  zipcode: Yup.string()
    .matches(/^\d{5}$|^\d{5}-\d{4}$/, 'Zipcode invalid')
    .required('required'),
})

const OrderTestForm = () => {
  let history = useHistory()
  let { id, testId } = useParams()
  const dispatch = useDispatch()
  const validateToken = (hasToken) => dispatch(validateTokenAction(hasToken))
  const user = useSelector((state) => state.auth.me)

  const initialValues = ORDER_TEST_FIELDS.reduce(
    (initialValues, field) => set(initialValues, field.name, user[field.name] || ''),
    {}
  )
  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)

    try {
      let order
      if (id && testId) {
        order = await saveAppointmentTestOrderShippingAddress(id, values)
      } else {
        order = await createTestOrder(values)
      }

      let token = localStorage.getItem('token')
      await validateToken(!isNull(token))

      history.push(`/order/test/checkout?testId=${order.id}`)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Formik initialValues={initialValues} validationSchema={ORDER_TEST_SCHEMA} onSubmit={onSubmit}>
      {(props) => (
        <form className="Form section columns stretch" onSubmit={props.handleSubmit}>
          {id && (
            <div className="message section">
              Thank you for completing your telehealth consultation with StreamMD! Based on your consultation, your
              doctor recommends that you order a Covid-19 test. Please fill out your shipping information below:
            </div>
          )}
          <div className="inner-section stretch form-fields">
            {ORDER_TEST_FIELDS.map((field) => (
              <Field key={field.name} error={props.errors.name} {...field} />
            ))}
          </div>
          <div className="inner-section fixed bottom">
            <button
              className={classNames('button primary', { inactive: !props.isValid })}
              type="submit"
              disabled={!props.isValid || props.isSubmitting}
            >
              {props.isSubmitting ? 'Submitting...' : 'Continue To Payment'}
            </button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default OrderTestForm
