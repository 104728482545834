import React from 'react'
import { isEmpty } from 'lodash'
import { FallbackCard } from '../ui'
import { Appointments } from '../icons'
import { isBundle } from '../../config'

const ScheduleAppointmentPrompt = () => {
  const hasIntakeData = !isEmpty(localStorage.getItem('intake'))
  const to = hasIntakeData ? '/schedule' : '/intake'
  return (
    <FallbackCard
      icon={Appointments}
      confirmTitle={isBundle && 'Have your tests arrived?'}
      confirmMessage={
        isBundle && 'Please note that you will need to have your PCR test in hand during your video appointment.'
      }
      confirmButtonText="Yes, schedule appointment"
      message={
        isBundle
          ? 'Have your tests arrived yet? If so, please schedule your appointment.'
          : 'You do not have any upcoming appointments...'
      }
      to={to}
      toText="Schedule an appointment"
    />
  )
}

export default ScheduleAppointmentPrompt
