const config = {
  ENV: process.env.REACT_APP_ENV || 'development',
  API_URL: process.env.REACT_APP_API_URL || '//localhost:5000',
  GEOCODING_API_KEY: 'AIzaSyD7wm1gFANRFglyCTlnYG4USMQiRyoHcgc',
  TZ_SUBSET: process.env.TZ_SUBSET || '/',
  CANCEL_HOURS: process.env.CANCEL_HOURS || 24,
  FTP_URL: process.env.REACT_APP_FTP_URL || 'ftp://ftp.drivehq.com/streammd/public/bridge.html',
  isBundle: process.env.REACT_APP_IS_BUNDLE === 'true' || false,
  PHILIPS_APP_URL: process.env.REACT_APP_PHILIPS_APP_URL || 'https://philips.streammd.com',
}

module.exports = config
