import { useQuery } from 'react-query'
import { protectedRequest } from '../requests/api'
// import { getPatientById } from './usePatient'

// TODO: Fix this, NOT USING HOOK/REACT QUERY PROPERLY!!!
export const createAppointment = (providers, when, isBundle, state) =>
  protectedRequest('POST', `appointments`, { providers, when, isBundle, state }).then((res) => res.data)

export const addAppointmentPatientData = (appointmentId, notes) =>
  protectedRequest('POST', `appointments/${appointmentId}/patient/data`, notes).then((res) => res.data)

export const cancelAppointment = (appointmentId) =>
  protectedRequest('POST', `square/refund/appointment/payment/${appointmentId}`).then((res) => res.data)

export const reassignAppointment = (appointmentId, when) =>
  protectedRequest('PUT', `appointments/${appointmentId}/reassign`, { when }).then((res) => res.data)

export const rescheduleAppointment = (appointmentId, providers, when, state) =>
  protectedRequest('PUT', `appointments/${appointmentId}/reschedule`, { providers, when, state }).then(
    (res) => res.data
  )

export const followUpAppointment = (appointmentId, providers, when) =>
  protectedRequest('POST', `appointments/${appointmentId}/schedule/follow/up`, { providers, when }).then(
    (res) => res.data
  )

export const endAppointment = (appointmentId, fields) =>
  protectedRequest('POST', `appointments/${appointmentId}/end`, { fields }).then((res) => res.data)

export const checkRefundStatus = (appointmentId) =>
  protectedRequest('GET', `square/refund/appointment/payment/${appointmentId}`).then((res) => res.data)

export const removeAppointment = (appointmentId) =>
  protectedRequest('DELETE', `appointments/${appointmentId}`).then((res) => res.data)

export const getAppointmentTestOrder = (_, appointmentId) =>
  protectedRequest('GET', `appointments/${appointmentId}/test/order`).then((res) => res.data)

export const saveAppointmentTestOrderShippingAddress = (appointmentId, data) =>
  protectedRequest('PUT', `appointments/${appointmentId}/test/order/shipping/address`, data).then((res) => res.data)

//todo: return res.data for following calls and cleanup components that use them
export const getAppointmentById = async (_, appointmentId) => protectedRequest('GET', `appointments/${appointmentId}`)

const getNextAppointment = async (_) => protectedRequest('GET', 'appointments/next')

export const useAppointmentTestOrder = (appointmentId) => {
  return useQuery(['appointmentOrder', appointmentId], getAppointmentTestOrder)
}

export const useAppointment = (appointmentId) => {
  //Seems like a weird way to do it to decide what call to use?
  const callToUse = appointmentId ? getAppointmentById : getNextAppointment
  return useQuery(['appointment', appointmentId], callToUse)
}
